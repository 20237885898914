<template>
    <a-modal
            width="60%"
            v-model="visible"
            title="导入图片"
            @ok="submitData"
            :afterClose="afterClose"
            @cancel="close">

        <upload
                ref="addlots"
                :defFileList="fileList"
                :fromOnly="false"
                :img-count="99"
        ></upload>

    </a-modal>
</template>

<script>
    import upload from './uploadImage'
    import API from '@/request'
    export default {
        name: "importPhoto",
        components:{
            upload
        },
        props:{
        },
        data(){
            return{
                fileList:[],
                visible:false
            }
        },
        methods:{
            showImportPhoto(){
                this.visible = true;
            },
            close(){
                this.visible = false;
            },
            afterClose(){
                this.fileList = [];
            },
           async submitData(){
                if(this.$refs.addlots.hasUploadingFile()){
                    this.$message.warning("当前图片正在上传中");
                    return
                }
                if(!this.$refs.addlots.hasImgInUploadResult()){
                    this.$message.warning("至少上传一张图片");
                    return
                }
                try {
                    let result = await API.importBatchPhoto({
                      batchImages:this.fileList
                    })
                    if(result.data.code == "0000"){
                        this.$message.success("导入成功");
                        this.$emit("importSuccess")
                        this.close();
                        return;
                    }
                }catch(e)
               {
                   console.log(e);
                  if(e.data.code == "0018"){
                        this.$emit("importSuccess")
                        this.close();
                    }
               }

            }
        }
    }
</script>

<style scoped>

</style>
