<template>
  <div class="clearfix">
    <a-spin :spinning="confirmLoading">
      <div class="ant-upload-picture-card-wrapper ">
        <draggable
          class="ant-upload-list ant-upload-list-picture-card"
          :list="fileList"
          :options="{ group: 'title', animation: 150 }"
          :no-transition-on-drag="true"
          @change="change"
        >
          <div
            class="ant-upload-list-picture-card-container"
            v-for="(items, index) in fileList"
            :key="index"
          >
            <span>
              <div class="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card">
                <div class="ant-upload-list-item-info">
                  <span class="ant-upload-list-item-thumbnail" >
                    <img
                      :src="isVideo(items)?items+'?x-oss-process=video/snapshot,t_0,f_jpg,w_100,h_0':items"
                      alt="image.png"
                      style="padding:0"
                      :class="{video_icon:isVideo(items)}"
                      class="ant-upload-list-item-image"
                    />
<!--                      <video v-if="isVideo" :src="items"  width="100%" height="100%"  >-->
<!--                      您的浏览器不支持 video 标签。-->
<!--                      </video>-->
                    <img style="position: absolute;left: 0;top: 0 ; padding: 25px" v-if="isVideo(items)" :src="icon_video "/>
                  </span>
                </div>
                <span class="ant-upload-list-item-actions">
                  <span @click="previewImg(index,items)">
                    <i aria-label="icon: eye-o" class="anticon anticon-eye-o">
                      <svg
                        viewBox="64 64 896 896"
                        data-icon="eye"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                        focusable="false"
                        class=""
                      >
                        <path
                          d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"
                        ></path></svg></i
                  ></span>
                  <span @click="deleteImg(index)">
                    <i
                      aria-label="icon: delete"
                      tabindex="-1"
                      class="anticon anticon-delete"
                      ><svg
                        viewBox="64 64 896 896"
                        data-icon="delete"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                        focusable="false"
                        class=""
                      >
                        <path
                          d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"
                        ></path>
                      </svg>
                    </i>
                  </span>
                </span>
              </div>
            </span>
          </div>
        </draggable>
        <div class="uplod_img">
          <a-upload listType="picture-card" class="upload_btn" :fileList="[]" :customRequest="uploadImage" :beforeUpload="beforeUpload" :multiple="true" accept="image/*">
            <div>
              <a-icon :type="loading ? 'loading' : 'upload'" />
              <div class="ant-upload-text">上传</div>
            </div>
<!--            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>-->
            <image-preview :img-preview-url="previewImage" :is-img-preview="previewVisible" @cancel="handleCancel"></image-preview>
            <video-preview :preview-url="previewVideo" :visible="previewVideoVisible" v-if="previewVideoVisible" @cancel="handleVideoCancel"></video-preview>
          </a-upload>
        </div>
      </div>
    </a-spin>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import upload from "@/utils/upload";
import API from "@/request";
import ImagePreview from "@/components/imagePreview";
import VideoPreview from "@/components/videoPreview";
import {compressImage} from "../assets/staticData/compressImage";

export default {
  name: "UploadImage",
  props: {
    defFileList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    imgCount: {
      type: Number,
      value: 3
    },
    isAllowImages:{
      type:Boolean,
      default:true
    },
    isAllowVideo:{
      type:Boolean,
      default:true
    },
    // 来自哪个组件，如果是true代表是 新建/编辑  要给上传图片的名称加时间戳，
    // 如果不是,那则是批量导入图片，则不需要加时间戳
    fromOnly:{   
      type:Boolean,
      default:false
    }
  },

  components: {
    ImagePreview,
    VideoPreview,
    draggable
  },
  watch: {
    defFileList: {
      handler(value) {
        this.fileList = value;
      }
    },
    imgCount: {
      handler(value) {
        this.count = value;
      }
    }
  },
  data() {
    return {
      icon_video:require("@/assets/image/video.svg"),
      // 文件列表
      fileList: this.defFileList,
      // 传入的数量
      count: this.imgCount,
      confirmLoading: false,
      // 是否显示预览弹框
      previewVisible: false,
      // 预览图片
      previewImage: "",
      // 加载
      loading: false,
      // 标记上传文件是否重复
      istag: undefined,
      // 上传最大张数
      fileMaxLength: false,

      // 是否显示预览弹框
      previewVideoVisible: false,
      // 预览视频
      previewVideo: "",

      uploadingFile:{
        uploadingCount:0,
        failUploadFiles:[],
      }

    };
  },

  methods: {
    hasUploadingFile(){
      return this.uploadingFile.uploadingCount > 0
    },
    hasImgInUploadResult(){
      return this.fileList.some(item => {
        return this.valiImgType(item)
      })

    },
    //上传图片格式
   valiImgType(str){
      // if(!/\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(str)){
      //   return false;
      // }
      if(/\.(webp|WEBP)$/.test(str)){
        return false
      }
      return  true
    },
    //上传MP4格式
   valiVideoType(str){
      if(!/\.(MP4|mp4)$/.test(str)){
        return false;
      }
      return  true
    },

    /**
     *拖拽事件
     */
    change(e) {
      console.log(e);
    },
    /**
     *判断是否选择的图片
     */
    beforeUpload(file) {
      if(this.isAllowImages && this.valiImgType(file.name)){
        return true
      }
      if(this.isAllowVideo && this.valiVideoType(file.name)){
        return true
      }

      return  false
    },
    /**
     * 关闭预览层
     */
    handleCancel() {
      this.previewVisible = false;
    },
    handleVideoCancel() {
      this.previewVideoVisible = false;
    },
    /**
     * 预览图片
     * @param uid
     */
    previewImg: function(uid,item) {
      if(this.isVideo(item)){
        this.previewVideoVisible = true;
        this.previewVideo = item;
        return;
      }
      this.previewVisible = true;
      this.fileList.forEach((item, idx) => {
        if (idx == uid) {
          this.previewImage = item;
        }
      });
    },
    /**
     * 删除图片
     * @param uid
     */
    deleteImg(uid) {
      var index = this.fileList.findIndex((item,index) => {
        return uid == index;
      })
      if(index!= -1){
        this.fileList.splice(index,1)
      }
    },

    getFileOnUrl(url){
      if(!url){
         url = ""
      }
      var index = url.lastIndexOf("/");
      if(index < 0){
        return ""
      }
      var fileName = url.substring(url.lastIndexOf("/") + 1,url.length);
      var suffixIndex = fileName.indexOf('.')
      if(suffixIndex == -1){
         return  fileName
      }
      // return fileName.substring(0,suffixIndex)
      //截取图片name对比
      return fileName
    },
    getFileName(fileName){
      var suffixIndex = fileName.indexOf('.')
      if(suffixIndex == -1){
        return  fileName
      }
      return fileName.substring(0,suffixIndex)
    },
    getFileSuffix(fileName){
      var suffixIndex = fileName.indexOf('.')
      if(suffixIndex == -1){
        return  ''
      }
      return fileName.substring(suffixIndex,fileName.length)
    },
    isVideo(url){
      if(!url){
        return false;
      }
      var index = url.lastIndexOf("/");
      if(index < 0){
        return false
      }
      var fileName = url.substring(url.lastIndexOf("/") + 1,url.length );
      var suffixIndex = fileName.lastIndexOf(".");
      if(suffixIndex < 0){
        return false
      }
      var suffixStr = fileName.substring(suffixIndex + 1,fileName.length );

      if(suffixStr.toLocaleUpperCase() != "MP4"){
        return  false
      }
      return  true;

    },



    // 封面图上传
    async uploadImage(action) {
      const file = action.file;
      let times = new Date().getTime()

      let name = file.name.substring(0, file.name.lastIndexOf("."));
      let suffix = file.name.substring(file.name.lastIndexOf("."), file.name.length);
      let filePath;
      if(this.fromOnly){
        filePath = "watch/list/";
      }else{
        filePath = "watch/batch/";
      }
      var pathName = filePath + name + '-' + times + suffix;
      this.loading = true;
      this.uploadingFile.uploadingCount++;

      //图片压缩
      compressImage(file,{}).then(result=>{
        var compressFile = new File([result],file.name,{type:file.type});
        upload.uploadFile(pathName, compressFile).then(res => {
          this.uploadingFile.uploadingCount--;
          if(this.uploadingFile.uploadingCount <= 0){
            this.loading = false;
          }
          if (this.fileList.length >= this.count) {
            this.fileList.shift();
          }

          /**
           * 覆盖
           */
          var index =  this.fileList.findIndex(item => {
            var itemName = this.getFileOnUrl(item);
            var resName = this.getFileOnUrl(res);
            return itemName == resName
          })
          if(index == -1){
            this.fileList.push(res);
          }else{
            // this.fileList[index] = res
            let timeStr = new Date().getTime()
            let newRes = res +'?'+ timeStr
            this.$set(this.fileList,index,newRes)
          }
          const regexp = /[^\d]+|\d+/g;
          const result = this.fileList.map(name => ({ name, weights: name.match(regexp) })).sort((a, b) => {
            let pos = 0;
            const weightsA = a.weights;
            const weightsB = b.weights;
            let weightA = weightsA[pos];
            let weightB = weightsB[pos];
            while (weightA && weightB) {
              const v = weightA - weightB;
              if (!isNaN(v) && v !== 0) return v;
              if (weightA !== weightB) return weightA > weightB ? 1 : -1;
              pos += 1;
              weightA = weightsA[pos];
              weightB = weightsB[pos];
            }
            return weightA ? 1 : -1;
          });
          for(let i=0;i<result.length;i++){
            const element = result[i].name
            this.$set(this.fileList,i,element)
          }
        }).catch(error => {
          this.uploadingFile.uploadingCount--;
          this.uploadingFile.failUploadFiles.push(file.name)
        });
      })

    },
    /**
     * 验证图片是否重复
     * @param newimg
     * @returns {Promise<void>}
     * @constructor
     */
    async VerifyImageDuplicate(newimg) {
      const parm = {
        img: newimg,
        imgList: this.fileList
      };
      let res = await API.duplicateRemoval(parm);
      if (res) {
        if (res.data.code == "0000") {
          this.loading = false;
          var imgArr = res.data.data;
          if (imgArr.length > 0) {
            this.istag = undefined;
            this.fileList.forEach((item, index) => {
              if (item == imgArr[0]) {
                this.istag = index;
              }
            });
            if (this.istag) {
              this.fileList.splice(this.istag, 1, imgArr[0]);
            }
          } else {
            if (this.fileList.length == this.count) {
              this.$message.warning("最大上传" + this.count + "张图片");
            } else {
              this.fileList.push(newimg);
            }
          }
        } else {
          this.loading = false;
        }
      }
    },
    async getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
  },
  mounted() {}
};
</script>
<style>
.upload_btn {
  display: block;
}
.ant-upload-select-picture-card {
  display: none;
}
.video_icon{
    padding: 20px;
}
.clearfix {
  width: 100%;
}
</style>
