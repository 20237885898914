<template>
  <div class="imagePreview">
    <a-modal
      class="preview_warp"
      :visible="isImgPreview"
      width="520px"
      centered
      :footer="null"
      @cancel="cancel"
      title="预览图片"
    >
      <img :src="imgPreviewUrl" alt="" />
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    imgPreviewUrl: {
      type: String
    },
    isImgPreview: {
      type: Boolean
    }
  },
  name: "imagePreview",
  components: {},
  data() {
    return {};
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    }
  },
  computed: {},
  created() {},
  mounted() {}
};
</script>

<style lang="less" scoped>
.preview_warp {
  img {
    width: 100%;
  }
}
</style>
