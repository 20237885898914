<template>
  <div class="auctionMangerPage">
    <div class="main">
      <a-space>
        <a-button type="primary" class="newLotsBtn" @click="newLotsClick">{{"新建腕表" }}</a-button>
        <a-button type="primary" class="newLotsBtn" @click="bulkImport">导入腕表</a-button>
        <a-button type="primary" class="newLotsBtn" @click="showImportPhoto">导入图片</a-button>
      </a-space>

      <a-table
          :columns="columns"
          :data-source="listData"
          @change="tablePageChange"
          bordered
          :scroll="{ x: 1400 }"
          :pagination="pagination"
          :current="pagination.current"
          :rowKey="(record) => record.watchSeriesId"
          :loading="tabLoding"
      >
        <template slot="title">
          <div class="tab_top_view">
            <a-space>
              <a-input-search
                  v-model="searchKeyword"
                  placeholder="请输入名称或型号查询"
                  enter-button
                  @search="onSearch"
                  class="search_View"
                  :loading="searchloading"
                  allow-clear
              />
            </a-space>
          </div>
        </template>
        <template slot="pic" slot-scope="text, record">
          <img style="width:50px;heigth:50px" :src="record.pic" />
        </template>
        <template slot="status" slot-scope="text, record">
          <a-tag
              color="green"
              v-if="record.status == 1"
              @click="listEnable(0, record)"
          >
            <span>正常</span>
          </a-tag>
          <a-tag v-else @click="listEnable(1, record)">
            <span>未启用</span>
          </a-tag>
        </template>

        <template slot="action" slot-scope="text, record">
          <div class="action_btn_view">
            <a-space>
              <a-button @click="editorFun(record)">编辑</a-button>
              <a-popconfirm
                  placement="top"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="deleteFun(record)"
              >
                <template #title>
                  <p>确定删除该记录吗？</p>
                </template>
                <a-button>删除</a-button>
              </a-popconfirm>
            </a-space>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 编辑弹出框 -->
    <a-modal width="80%" v-model="isAdd" :title="header_title" @ok="submitData" @cancel="close">
      <div>
        <a-tabs default-active-key="1" @change="tabSelectIndex" :activeKey="activeKey">
          <a-tab-pane key="1" tab="基本信息">
            <template>
              <a-form-model :model="formData" ref="lotsForm" :rules="watchRules">
                <div class="form_content">
                  <div class="form_item">
                    <a-form-model-item required label="名称:" prop="name" >
                      <a-input
                          v-model="formData.name"
                          placeholder="请输入腕表名称"
                          :maxLength="100"
                      />
                    </a-form-model-item>
                    <a-form-model-item required label="腕表型号：" prop="model">
                      <a-input
                          :maxLength="100"
                          v-model="formData.model"
                          placeholder="请输入腕表型号"
                      />
                    </a-form-model-item>
                    <a-form-model-item label="机芯 机芯:" name="desc">
                      <a-input
                          v-model="formData.movementParameters.movement"
                          placeholder="请输入腕表的机芯信息"
                          :maxLength="50"
                      />
                    </a-form-model-item>

                    <a-form-model-item label="机芯 振频：">
                      <a-input
                          prefix="振次/小时"
                          type="text"
                          v-model="formData.movementParameters.vibrationFrequency"
                          placeholder="请输入腕表的振频，如：360000|5"
                          class="more-padding"

                      />
                    </a-form-model-item>
                  </div>
                  <div class="form_item">
                    <a-form-model-item required label="系列名:" prop="seriesName">
                      <a-input
                          v-model="formData.seriesName"
                          placeholder="请输入腕表系列名"
                          :maxLength="100"
                      />
                    </a-form-model-item>
                    <!-- <a-form-model-item required label="SKU:" prop="watchSku">
                      <a-input
                          v-model="formData.watchSku"
                          placeholder="请输入腕表SKU"
                          :maxLength="50"
                      />
                    </a-form-model-item> -->

                    <a-form-model-item label="机芯 组件：">
                      <a-input
                          v-model="formData.movementParameters.component"
                          placeholder="请输入腕表的组件信息"
                      />
                    </a-form-model-item>
                    <a-form-model-item label="机芯 动力存储：">
                      <a-input
                          prefix="小时"
                          type="text"
                          v-model="formData.movementParameters.powerStorage"
                          placeholder="请输入腕表的动力存储，如：50"

                      />
                    </a-form-model-item>
                  </div>
                </div>
                <div class="other_msg">
                  <a-form-model-item label="功能：">
                      <a-textarea
                          v-model="formData.feature"
                          placeholder="腕表功能详情，小程序显示在腕表机芯下方"
                          :maxLength="4000"
                      />
                    </a-form-model-item>
                </div>
                <div class="form_content">
                  <div class="form_item">
                    <a-form-model-item label="表壳 材质:" name="desc">
                      <a-input
                          v-model="formData.watchCases.material"
                          placeholder="请输入腕表表壳的材质"
                          :maxLength="50"
                      />
                    </a-form-model-item>
                    <a-form-model-item label="表壳 防水:" name="desc">
                      <a-input
                          prefix="ATM"
                          v-model="formData.watchCases.waterproofDepth"
                          placeholder="请输入腕表表壳的防水深度，如：60"
                          :maxLength="50"
                      />
                    </a-form-model-item>
                    <a-form-model-item label="表带/表链 材质:" name="desc">
                      <a-input
                          v-model="formData.watchOtherInfo.material"
                          placeholder="请输入腕表表带/表链的材质"
                          :maxLength="50"
                      />
                    </a-form-model-item>

                  </div>
                  <div class="form_item">

                    <a-form-model-item label="表壳 直径:">
                      <a-input
                          prefix="毫米"
                          v-model="formData.watchCases.diameter"
                          placeholder="请输入腕表表壳的直径，如：36"
                          :maxLength="50"
                      />
                    </a-form-model-item>
                    <a-form-model-item label="表壳 表盘:">
                      <a-input
                          v-model="formData.watchCases.dialInfo"
                          placeholder="请输入腕表表壳的表盘信息"
                          :maxLength="50"
                      />
                    </a-form-model-item>
                    <a-form-model-item label="表带/表链 表扣:">
                      <a-input
                          v-model="formData.watchOtherInfo.buckleStyle"
                          placeholder="请输入腕表表带/表链的表扣样式"
                          :maxLength="50"
                      />
                    </a-form-model-item>

                  </div>
                </div>
                <!-- <div class="other_msg">
                  <a-form-model-item label="序列号：">
                      <a-textarea
                          v-model="formData.serialNos"
                          placeholder="请输入此型号腕表下的所有序列号，多个序列号请用|隔开"
                          :maxLength="2000"
                      />
                    </a-form-model-item>
                </div> -->
              </a-form-model>
            </template>
          </a-tab-pane>
          <a-tab-pane key="2" tab="图片及其他" force-render>
            <div class="img_tabview">
              <a-form-model :model="formData" ref="imgForm" :rules="imgRules">
                <a-form-item required prop="img">
                  <span slot="label">
                    主图
                    <a-tooltip title="主图用于显示在my zenith列表，需要透明背景">
                      <a-icon type="exclamation-circle" class="tip_ico" />
                    </a-tooltip>
                  </span>
                </a-form-item>
                <a-upload
                    listType="picture-card"
                    class="upload_btn"
                    :fileList="coverList"
                    :customRequest="uploadCover"
                    @preview="handlePreview"
                    :remove="delCover"
                    accept="image/*"
                >
                  <div v-if="coverList.length < 1">
                    <a-icon :type="loading ? 'loading' : 'upload'" />
                    <div class="ant-upload-text">
                      上传
                    </div>
                  </div>
                </a-upload>
                <a-modal
                    :visible="previewVisible"
                    :footer="null"
                    @cancel="previewCancel"
                    title="预览图片">
                  <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
              </a-form-model>
            </div>
            <div class="img_tabview">
              <a-form-model :model="formData" ref="imgForm" :rules="imgRules">
                <a-form-item required prop="img">
                  <span slot="label">
                    图片
                    <a-tooltip title="图片用于显示在my zenith详情页，拖拽图片调整顺序">
                      <a-icon type="exclamation-circle" class="tip_ico" />
                    </a-tooltip>
                  </span>
                </a-form-item>
                <upload-image
                    ref="addlots"
                    :defFileList="formData.imgList"
                    :fromOnly="true"
                    :img-count="99"
                ></upload-image>
              </a-form-model>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-modal>
    <!--    导入腕表-->
    <import-file
        :is-import="isImport"
        groupId="2"
        @closeModel="closeModel"
        @importSuccess="importSuccess"
    ></import-file>
    <import-photo ref="importPhoto"  groupId="2" @importSuccess="importSuccess"></import-photo>

  </div>
</template>

<script>
import UploadImage from "@/components/uploadImage.vue";
import API from "@/request";
import ImportFile from "@/components/importFile";
import importPhoto from "@/components/importPhoto"
import { areaData } from "@/assets/staticData/staticData";
import {mixin} from '../commonData'
import {compressImage} from "../../assets/staticData/compressImage";
import upload from "@/utils/upload";
export default {
  name: "auctionMangerPage",
  components: { ImportFile, UploadImage,importPhoto },
  mixins:[
    mixin
  ],
  data() {
    return {
      columns: [
        {
          title: "名称",
          dataIndex: "name"
        },
        {
          title: "图片",
          dataIndex: "mainImg",
          align: "center",
          customRender: (text, record) => {
            if (text != "" && text != null){
              return <img src={text} class="list_img" style="width:180px;height:140px;object-fit: contain;"/>;
            }else {
              return <span>-</span>;
            }
            /*if (text != "" && text != null) {
              var arr = text.split(",");
              var index =  arr.findIndex(item=>{
                return this.valiIsNotVideo(item)
              })
              console.log(text)
              if(index != -1){
                return <img src={arr[index]} class="list_img" style="width:180px;height:140px;object-fit: contain;"/>;
              }else{
                return <span>-</span>;
              }
            } else {
              return <span>-</span>;
            }*/
          },
        },
        {
          title: "型号",
          dataIndex: "model"
        },
        {
          title: "操作",
          dataIndex: "aaa",
          scopedSlots: { customRender: "action" },
        }
      ],
      watchRules: {
        name: [
          {
            required: true,
            message: "请输入腕表名称",
             trigger: "blur"
          },
        ],
        model: [
          {
            required: true,
            message: "请输入腕表型号",
            trigger: "blur"
          },
        ],
        seriesName: [
          {
            required: true,
            message: "请输入腕表系列名",
            trigger: "blur"
          },
        ],
        // watchSku: [
        //   {
        //     required: true,
        //     message: "请输入腕表SKU",
        //     trigger: "blur"
        //   },
        // ],
        number: [
          {
            required: true,
            message: "请输入拍品编号",
            // trigger: "blur"
          },
        ],
      },
      imgRules: {
        img: [
          {
            required: true,
            message: "请选择腕表图片",
          },
        ],
      },
      formData: {
        about : "",
        feature : "",
        imgUrls : "",
        model   : "",
        name    : "",
        // serialNos : "",
        seriesName  : "",
        movementParameters : {
          component : "",
          movement  : "",
          powerStorage  : "",
          vibrationFrequency : "",
        },
        watchCases  : {
          dialInfo  : "",
          diameter  : "",
          material  : "",
          waterproofDepth : ""
        },
        watchOtherInfo  : {
          buckleStyle : "",
          material  : ""
        },
        watchSku  : "",
        watchSeriesId : "",
        imgList : []
      },
      selectedRowKeys: [],
      listData: [],
      coverList : [], //主图
      previewVisible: false,  //主图预览
      previewImage: "", // 主图预览字段
      changStatus: "显示全部",
      isAdd: false,

      header_title: "新增腕表",
      isImport: false, //导入拍品
      loading : false,
      auction_title: "",
      searchloading: false,
      detail_placeholder: "请按如下合适填写\n材质：白金 \n类型：求婚戒指",
      itemData: {},
      activeKey: "1",
      lotsFileList: "",
      pageSize: "10",
      tabLoding: false,
      searchKeyword: "",
      searchStatus: "",
      changItemArr: [],
      pagination: {
        current: 1,
        total: 0,
        pageSize: 10, //每页中显示10条数据
        showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
      },
      isSubmitInfo: false, //点击新建可能会重复提交数据
    };
  },
  methods: {
    close() {
      if (this.$refs.lotsForm != undefined) {
        this.$refs.lotsForm.resetFields();
      }
      if (this.$refs.imgForm != undefined) {
        this.$refs.imgForm.resetFields();
      }

      this.activeKey = "1";
      this.formData = {
        about : "",
        feature : "",
        imgUrls : "",
        model   : "",
        name    : "",
        // serialNos : "",
        seriesName  : "",
        movementParameters : {
          component : "",
          movement  : "",
          powerStorage  : "",
          vibrationFrequency : "",
        },
        watchCases  : {
          dialInfo  : "",
          diameter  : "",
          material  : "",
          waterproofDepth : ""
        },
        watchOtherInfo  : {
          buckleStyle : "",
          material  : ""
        },
        watchSku  : ""
      };
      this.isSubmitInfo = false;
    },
    /**
     * 新增腕表-显示弹框
     */
    newLotsClick() {
      this.header_title = "新增腕表";
      this.isAdd = true;
      this.coverList = [];
      var that = this;
      this.close();

    },
    /**
     * 导入成功的回调
     */
    importSuccess() {
      this.getListData();
    },
    /**
     * 主图预览
     */
    handlePreview() {
      // 主图预览方法
      this.previewVisible = true;
      this.previewImage = this.coverList[0].url;
    },
    previewCancel() {
      // 轮播图取消预览
      this.previewVisible = false;
      this.previewImage = "";
    },
    /**
     * 删除腕表
     */
    async deleteFun(e) {

      console.log(e.watchSeriesId);
      const param = {
        watchSeriesId: e.watchSeriesId,
      };
      let res = await API.deletelots(param).catch((err) => {
        console.log(err);
      });

      if (res.data.code === "0000") {
        this.$message.success("删除成功");
      }
      this.getListData();
    },
    /**
     * 分页点击
     */
    tablePageChange(e) {
      this.pagination.current = e.current;
      // this.getListData();
      this.searchData()
    },

    showImportPhoto(){
      if(this.$refs['importPhoto']){
        this.$refs['importPhoto'].showImportPhoto();
      }
    },

    /**
     * 导入腕表
     */
    bulkImport() {
      this.isImport = true;
    },
    closeModel() {
      this.isImport = false;
    },
    // 主图上传
    uploadCover(action) {


      /*if (this.formData.watchSku == ""){
        this.$message.warning("请先完善基本信息");
        return
      }*/
      const file = action.file;
      if (
          file.type == "image/png" ||
          file.type == "image/bmp" ||
          file.type == "image/jpeg" ||
          file.type == "image/jpg"
      ) {
        let size = file.size / 1048576;

        if (size > 2) {
          this.$message.warning("图片文件不能超过2M");
          return;
        }
        let pathName =
            "watch/main/" +
            new Date().valueOf() +
            parseInt(Math.random() * 10000) +
            '_' +file.name;
        this.loading = true;
        let config = {
          //width : 400, //默认尺寸为原图尺寸的0.75
          //height : 300,
          //quality : 0.5  //如果不传,默认0.5
        };

        compressImage(file,config)
            .then(result => {
              var compressFile = new File([result],file.name,{type:file.type});
              upload.uploadFile(pathName, compressFile).then((res) => {
                this.loading = false;
                this.coverList.push({
                  uid: "-1",
                  name: "image.png",
                  status: "done",
                  url: res,
                });
                /*this.formData.materials = this.coverList[0].url;*/
              });
            });
      } else {
        this.$message.warning("不支持的文件格式");
      }
    },
    tabSelectIndex(e) {
      this.activeKey = e;
    },
    /**
     * 编辑信息
     * @param e
     */
    async editorFun(e) {

      const param = {
        watchSeriesId : e.watchSeriesId
      };

      let res = await API.getWatchById(param).catch((err) => {
        console.log(err);
      });
      if (res.data.code === "0000") {
        this.tabLoding = false;
        this.isAdd = true;
        var result = res.data.data;

        this.formData.special_notice = result.specialNotice;
        this.header_title = "修改腕表";
        if (result.mainImg) {
          this.coverList = [{
            uid: "-1",
            name: "image.png",
            status: "done",
            url: result.mainImg
          }];
        }else{
          this.coverList = [];
        }

        var imgList = [];
        result.imgList.forEach(function (item) {
          imgList.push(item.imgUrl)
        })

        this.formData.imgList = imgList;

        this.formData.about = result.about;
        this.formData.feature = result.feature;
        this.formData.model = result.model;
        this.formData.name = result.name;
        // this.formData.serialNos = result.serialNos;
        this.formData.seriesName = result.seriesName;
        this.formData.watchSku = result.watchSku;
        this.formData.movementParameters.component = result.movementParameters.component;
        this.formData.movementParameters.movement = result.movementParameters.movement;
        this.formData.movementParameters.powerStorage = result.movementParameters.powerStorage;
        this.formData.movementParameters.vibrationFrequency = result.movementParameters.vibrationFrequency;
        this.formData.watchCases.dialInfo = result.watchCases.dialInfo;
        this.formData.watchCases.diameter = result.watchCases.diameter;
        this.formData.watchCases.material = result.watchCases.material;
        this.formData.watchCases.waterproofDepth = result.watchCases.waterproofDepth;

        this.formData.watchOtherInfo.buckleStyle = result.watchOtherInfo.buckleStyle;
        this.formData.watchOtherInfo.material = result.watchOtherInfo.material;
        this.formData.watchSeriesId = result.watchSeriesId

      }

      var that = this;

    },

    /**
     * 弹出框提交数据
     */
    async submitData() {


      if(this.$refs.addlots.hasUploadingFile()){
        this.$message.warning("当前图片正在上传中");
        return
      }
      // if(!this.$refs.addlots.hasImgInUploadResult()){
      //   this.$message.warning("至少上传一张缩略图");
      //   return
      // }

      if (this.coverList.length === 0){
        this.$message.warning("请上传主图");
        return
      }


      var param = {};

      param = {
        about: this.formData.about,
        feature: this.formData.feature,
        imgUrls: this.$refs.addlots.fileList,
        mainImg: this.coverList[0].url,
        model: this.formData.model,
        name: this.formData.name,
        // serialNos: this.formData.serialNos,
        seriesName: this.formData.seriesName,
        watchSku: this.formData.watchSku,
        movementParameters : {
          component : this.formData.movementParameters.movement,
          movement : this.formData.movementParameters.movement,
          powerStorage : this.formData.movementParameters.powerStorage ,
          vibrationFrequency : this.formData.movementParameters.vibrationFrequency
        },
        watchCases : {
          dialInfo  : this.formData.watchCases.dialInfo,
          diameter  : this.formData.watchCases.diameter,
          material  : this.formData.watchCases.material,
          waterproofDepth : this.formData.watchCases.waterproofDepth
        },
        watchOtherInfo : {
          buckleStyle : this.formData.watchOtherInfo.buckleStyle,
          material  : this.formData.watchOtherInfo.material
        }
      };
      console.log(this.header_title);
      /*if (imgUrl == "") {
        this.$message.warning("还没有上传图片");
        return;
      }*/

      console.log(this.header_title);
      //新增
      if(this.header_title != "新增腕表"){
        param.watchSeriesId = this.formData.watchSeriesId;
        console.log(this.formData.watchSeriesId);
        let res = await API.editorwatch(param).catch((err) => {
          console.log(err);
        });

        if (res.data.code === "0000") {
          this.$message.success("编辑成功");
        }
      }else{
        if (this.isSubmitInfo) {
          // this.$message.warning("新增中，请勿重复提交！");
          return;
        }
        this.isSubmitInfo = true;
        let res = await API.newlots(param).catch((err) => {

          this.isSubmitInfo = false;
        });
        if (res.data.code === "0000") {
          this.$message.success("新增成功");
        }
      }

      this.isAdd = false;
      this.getListData();

    },

    /**
     * 根据关键字搜索
     */
    onSearch(e) {

      this.pagination.current = 1
      this.searchKeyword = e;
      this.searchloading = true;
      this.searchData();
    },
    /**
     * 根据条件检索列表
     */
    async searchData() {
      const param = {
        current: this.pagination.current,
        size: this.pageSize,
        keyword: this.searchKeyword,
      };
      this.tabLoding = true;
      let res = await API.getWatchMainDataList(param);

      if (res.data.code === "0000") {
        this.tabLoding = false;
        this.searchloading = false;
        this.listData = res.data.data.records;
        const pagination = { ...this.pagination };
        pagination.total = res.data.data.total;
        this.pagination = pagination;
      }
    },
    delCover() {
      // 删除主图
      this.coverList = [];
      // this.formData.imgList = [];
    },

    /**
     * 获取列表数据
     * @returns {Promise<void>}
     */
    async getListData() {
      this.tabLoding = true;
      const param = {
        current: this.pagination.current,
        size: this.pageSize,
        keyword: ""
      };
      let res = await API.getWatchMainDataList(param).catch((err) => {
        console.log(err);
      });
      if (res.data.code === "0000") {
        this.tabLoding = false;
        this.listData = res.data.data.records;
        const pagination = { ...this.pagination };
        pagination.total = res.data.data.total;
        this.pagination = pagination;
      }
    },

  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  created() {
    this.itemData = this.$route.query;
    this.getListData();
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.pageHead {
  background: white;
}

.main {
  margin: 24px;
  background: #ffffff;
  padding: 24px;
}

.newLotsBtn {
  margin-bottom: 16px;
}

.search_View {
  width: 230px;
}

.tab_top_view {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

/deep/ .ant-tabs-bar .ant-tabs-nav-container {
  display: flex;
  justify-content: space-around;
}

.form_content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
}

.form_content .form_item {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  padding: 10px;
}
/deep/.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 50px;
}

/deep/.more-padding .ant-input:not(:first-child) {
  padding-left: 80px !important;
}
.form_content .form_item .ant-row.ant-form-item {
  width: 90%;
}

.other_msg {
  //padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 0 35px;
}

.img_tabview .ant-row .ant-form-item {
  display: flex;
}
.action_btn_view {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.action_btn {
  margin-right: 20px;
}
.img_tabview /deep/ .ant-row.ant-form-item {
  //display: flex;
  //flex-direction: row;
}
.img_tabview /deep/ .ant-upload-picture-card-wrapper {
  //display: flex;
}
.tip_ico {
  margin: 0 2px;
}
/deep/ .ant-input-number {
  width: 100%;
}
.list_img {
  max-height: 100px;
  max-width: 100px;
}
form .ant-mentions,
form textarea.ant-input {
  height: 100px;
}
/deep/ .img_tabview .ant-row .ant-form-item {
  //width: 100px;
}
/deep/ .img_tabview .ant-form-horizontal {
  display: flex;
}
</style>
