<template>
  <div class="imagePreview">
    <a-modal
      class="preview_warp"
      :visible="visible"
      width="520px"
      centered
      :footer="null"
      @cancel="cancel"
      title="预览视频"
    >
      <video width="100%" controls>
        <source :src="previewUrl" type="video/mp4">
        <source :src="previewUrl" type="video/ogg">
        您的浏览器不支持Video标签。
      </video>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    previewUrl: {
      type: String
    },
    visible: {
      type: Boolean
    }
  },
  name: "videoPreview",
  components: {},
  data() {
    return {};
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    }
  },
  computed: {},
  created() {},
  mounted() {}
};
</script>

<style lang="less" scoped>
.preview_warp {
  img {
    width: 100%;
  }
}
</style>
