export const mixin =  {
    data(){
        return{
            special_notice_options:[
                {label:'高估价拍品',value:1},
                {label:'含进出口限制材质',value:2},
                {label:'此乃保证项目',value:3},
                {label:'已有竞投方就拍品向苏富比作出了不可撤销投标的承诺',value:4},
                {label:'不设底价',value:5},
            ],
        }
    },
    methods:{
        valiImgType(str){
            if(!/\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(str)){
                return false;
            }
            return  true
        },
        valiIsNotVideo(str){
            if(!/\.(mp4|MP4)$/.test(str)){
                return true;
            }
            return  false
        },
        showImportPhoto(){
          if(this.$refs['importPhoto']){
              this.$refs['importPhoto'].showImportPhoto();
          }
        },
        hasImgFileInStr(str){
            if(!str || str.trim().length < 1){
                return false
            }
            var arrays = str.split(",");
            if(arrays.length < 1){
                return  false;
            }
            if(!arrays.some(item => {
                return this.valiImgType(item);
            })){
                return  false
            }
            return true;
        },
    }
}


